export default {

    APP_NAME: 'Cat images',
    APP_VER: 'v0.1',
    APP_UPDATE: '2020-01-13',
    APP_AUTHOR: 'Iwona Lalak',
    APP_AUTHOR_SITE: 'https://iwonalalak.pl',
    APP_AUTHOR_GIT: 'https://github.com/IwonaLalak',
    APP_REPOSITORY: 'https://github.com/IwonaLalak/cat-images-app',

}